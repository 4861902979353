import React, { Component } from 'react';

import axios from 'axios';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";

class Usage extends Component {

    constructor(props) {
        super(props);
       
        this.state = {
            usageCount: 0,
            usageData: [],
            fromDate: new Date(1593541800000), // 1593541800000: 01/July/2020
            toDate: new Date(),
            fromDateFlag: 0,
            endDateFlag: +new Date(),
            success_count: 0    
            // name: '',
            // apiKey: '',
            // apiId: '',
            // organization: ''
        }

        this.handleFromDateChange = this.handleFromDateChange.bind(this);
        this.handleToDateChange = this.handleToDateChange.bind(this);
    }
    
    handleFromDateChange = (e) => {
        // console.log(e)
        this.setState({
            fromDate: e,
            fromDateFlag: +new Date(e)
        })
    }

    handleToDateChange = (e) => {
        // console.log(e)
        this.setState({
            toDate: e,
            endDateFlag: +new Date(e)
        })
    }

    componentDidMount(){

        // console.log(this.props)
        const pathname = this.props.location.pathname
        const data =  pathname.split('usage/')
        const usageData = JSON.parse(data[1]);
         const options = {
            method: 'GET',
            url: 'https://cust.3dmu.prototechsolutions.com/local/log/'+usageData.id,
         }

        const response = axios(options);
        
        response.then(
            res => {
                const responseData = res.data.body;
                const usage = responseData['api_usage'];
                this.setState({
                    usageCount: usage.length,
                    usageData: usage,
                    success_count: responseData.success_count
                });
                // console.log(responseData)
            }
        )
        .catch(
            err => console.log(err)
        )
        
    }


    render() {
        // console.log(this.state);
        const { usageData, fromDateFlag, endDateFlag, success_count } = this.state;
        const formattedData =  usageData.map(
            data => {
                const logData = {}; 
                const keys = Object.keys(data);
                const d = new Date(parseInt(keys[0]));
                const value = data[keys[0]];
                const ddMMyyyy = d.getDate() + '/' + (d.getMonth() +1 ) + '/' + d.getFullYear() + ' : '+ d.getHours()  +':' + d.getMinutes() + ':' + d.getSeconds();
                logData['timestamp'] = keys[0];
                logData['time'] = ddMMyyyy;
                logData['type'] = value.type;
                logData['fileurl'] = value.fileurl;
                if(value.auto_align !== undefined){
                    logData['auto_align'] = value.auto_align.toString();
                }
                else logData['auto_align'] = 'NOT as Request Params';
                
                logData['status'] = value.status;
                // logData['time'] = time;
                // logData['time'] = time;
                return logData;
            }
        )



        let withinRange = formattedData.filter(
            item => {
                  if( item['timestamp'] > fromDateFlag && item['timestamp'] < endDateFlag){
                    return item;
                } else {
                    return '';
                }
            }
        );

       withinRange.sort((second, first) => {
            var d1 = new Date(first.timestamp);
            var d2 = new Date(second.timestamp);
            if(d2 < d1){
              return 1;
            } else if(d2 > d1){
              return -1;
            } else {
              return 0;
            }
  
          });

        const conditionalRowStyles = [
        {
            when: row => row.status === 'failed' ,
            style: {
            backgroundColor: 'rgba(240, 100, 100, 0.9)',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
            },
            },
        }]

        const columns = [
            {
                name: 'Time',
                selector: 'time',
                sortable: true,
                sortFunction: () =>  withinRange.sort((second, first) => {
                  var d1 = new Date(first.timestamp);
                  var d2 = new Date(second.timestamp);
                  if(d2 < d1){
                    return 1;
                  } else if(d2 > d1){
                    return -1;
                  } else {
                    return 0;
                  }
        
                }),
                grow: 2,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: true,
                
            },
            {
                name: 'File',
                selector: 'fileurl',
                sortable: true,
                grow: 10,
            },
            {
                name: 'Auto Align',
                selector: 'auto_align',
                sortable: true,
                grow: 2,
            },
            {
                name: 'Status',
                selector: 'status',
                sortable: true,
                grow: 2,
            }
        ]

        return (
           <>
               <table style={{"width" : "50%", "border": "1px solid black"}}>
               <tr style={{"width" : "50%",  "border": "1px solid black"}}>
                   <td style={{"width" : "50%",  "border": "1px solid black"}}>
                         Total Usage
                   </td>
                   <td>
                         {usageData.length}
                   </td>
               </tr>
               <tr style={{"width" : "50%",  "border": "1px solid black"}}>
                   <td style={{"width" : "50%",  "border": "1px solid black"}}>
                        Success Count
                   </td>
                   <td>
                        {success_count.toString()}
                   </td>
               </tr>
               <tr style={{"width" : "50%",  "border": "1px solid black"}}>
                   <td style={{"width" : "50%",  "border": "1px solid black"}}>
                         From 
                   </td>
                   <td>
                        <DatePicker 
                        selected={this.state.fromDate}
                        onChange={this.handleFromDateChange}
                        
                    />
                   </td>
               </tr>
               <tr style={{"width" : "50%",  "border": "1px solid black"}}>
                   <td style={{"width" : "50%",  "border": "1px solid black"}}>
                         To 
                   </td>
                   <td>
                            <DatePicker 
                            selected={this.state.toDate}
                            onChange={this.handleToDateChange}
                        />
                   </td>
               </tr>
           </table>
            <DataTable 
                data={withinRange}
                columns={columns}
                pagination
                fixedHeader
                conditionalRowStyles={conditionalRowStyles}
                defaultSortField='time'
            />
           </>
        );
    }
}

export default Usage;