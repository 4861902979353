import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./LoginPage.css"; // Import the CSS file for styling
import logo3dmu from "../../assets/images/3dmuLogo.png";
import CryptoJS from "crypto-js";
import enc from "./encc.txt";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    let JsonArr = [];
    // const decodedString = btoa(password);
    let validUserinputs = false;
    let data = "";

    const response = await fetch(enc);
    const text = await response.text();
    data = text;
    const key = "testing_models_donot_delete_or_change";
    const bytes = CryptoJS.AES.decrypt(data, key);
    const outputData = bytes.toString(CryptoJS.enc.Utf8);
    JsonArr = JSON.parse(outputData);

    for (let i = 0; i < JsonArr.length; i++) {
      // Get the current user object
      const user = JsonArr[i];
      // Get the email and password from the user object
      const userEmail = Object.keys(user)[0];
      const userPassword = user[userEmail];
      // Check if the email and password match
      if (userEmail === email && userPassword === password) {
        validUserinputs = true;
      }
    }
    if (validUserinputs) {
      sessionStorage.setItem("LogInState", true);
      history.push("/dashboard", { email });
    } else {
      sessionStorage.setItem("LogInState", false);
      alert("Please input correct email Id and password");
    }
  };

  return (
    <div className="login-container">
      <header>
        <img src={logo3dmu} alt="Logo" height="120px" />
      </header>
      <div className="login-form">
        <h2>Customer Dashboard</h2>
        <form>
          <div>
            <label>Email:</label>
            <input type="email" value={email} onChange={handleEmailChange} />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <button type="button" onClick={handleLogin}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
