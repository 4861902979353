import React from 'react';

import { Provider } from 'react-redux';

import './App.css';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import store from './store'

import Dashboard from './components/landings/Dashboard';
import LoginPage from './components/landings/Login';
// import PrivateRoute from './PrivateRoute';

import Usage from './components/usage/Usage';

function App() {
  console.log(window.location.hash);

  const token  = getIdToken(window.location.hash);
  console.log(token);
  store.dispatch({
    type: 'TOKEN',
    payload: token
  });
  return (
    <Provider store={store}>
    <Router>
    <div >

     {/* <Route exact path='/signin' component={SignIn}/> */}
     <Route exact path='/' component={LoginPage}/>
     <Route exact path='/dashboard' component={Dashboard}/>
     {/* <PrivateRoute exact path='/dashboard' component={Dashboard}/> */}
     <Route path='/usage' component={Usage}/>
    </div>
    </Router>
    </Provider>
  );
}

function getIdToken(fullTokenString){
   let indexIdToken = fullTokenString.indexOf('id_token=') ;
   let indexAccessToken = fullTokenString.indexOf('access_token=');
   let startIndex = indexIdToken + 9;
   let endIndex;
   if(indexIdToken < indexAccessToken){
     endIndex = indexAccessToken;
   } else {
      endIndex = fullTokenString.indexOf('&token_type');
      // endIndex = indexIdToken;
   }

   return fullTokenString.substring(startIndex, endIndex);

}

export default App;
