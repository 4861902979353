import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './reducers/authReducer'

const initState = {
    isLogin: false,
    api_key: ''
}

const middleware = [thunk];

const store = createStore(
    authReducer,
    initState, 
    compose(
          applyMiddleware(...middleware),
        //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    )

export default store