
 const authReducer = (state, action) => {
    switch(action.type){
        case 'LOGIN':
            state.isLogin = true;
            state.api_key = action.api_key;
            return state;
        case 'TOKEN' : 
            state.token = action.payload;
            return state;
        default:
            return state;
    }
}

export default authReducer;